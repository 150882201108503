import {IAction} from '../store'
import {
  ADD_ITEM_CREATE_ORDER,
  DELETE_ITEM_CREATE_ORDER,
  UPDATE_ITEM_CREATE_ORDER,
  SET_DATA_TO_EDIT,
  RESET_CREATE_ORDER,
  BULK_UPDATE_ITEM_CREATE_ORDER,
  SET_THEME_ID,
} from './CreateOrderActionTypes'

export interface ICreateOrderState {
  data: any[]
  themeId: any
  dataToEdit: any
  error?: string
}

const InitialState: ICreateOrderState = {
  data: [],
  themeId: {},
  dataToEdit: {},
}

const CreateOrderReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}
  const addIdx = (data: any[]) =>
    data.map((item, index) => {
      return {...item, no: index + 1}
    })
  switch (action.type) {
    case ADD_ITEM_CREATE_ORDER:
      let data = [...newState.data, ...action.payload.data]
      data = addIdx(data)
      newState.data = data
      break
    case UPDATE_ITEM_CREATE_ORDER:
      newState.data = newState.data.map((i) =>
        i.no === action.payload.data.no ? {...action.payload.data} : {...i}
      )
      break
    case BULK_UPDATE_ITEM_CREATE_ORDER:
      newState.data = addIdx(action.payload.data)
      break
    case DELETE_ITEM_CREATE_ORDER:
      newState.data = addIdx(newState.data.filter((i) => i.no !== action.payload.data.no))
      break
    case SET_DATA_TO_EDIT:
      newState.dataToEdit = action.payload.data
      break
    case RESET_CREATE_ORDER:
      newState = InitialState
      break
    case SET_THEME_ID:
      newState.themeId = action.payload
      break
    default:
  }
  return newState
}

export default CreateOrderReducer
