/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
// import {Languages} from './Languages'
// import {toAbsoluteUrl} from '../../../helpers'
import {RootState} from '../../../../app/redux/rootReducer'
import {logoutAct} from '../../../../app/redux/auth'
import {BsPersonFill} from 'react-icons/bs'

// interface User {
//   name:string,
//   id:string
// }
const HeaderUserMenu: FC = (props) => {
  const profile: any = useSelector((state: RootState) => state.auth.profile) || {}
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(logoutAct())
  }
  // const [user, setUser] = useState<User>({
  //   name:"",
  //   id:""
  // })

  // useEffect(() => {
  //   if(profile){
  //     setUser({
  //       name:profile.name,
  //       id:profile.id
  //     })
  //   }
  // }, [profile])

  // console.log('profile', profile)

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label font-weight-bold' style={{fontSize: '40px'}}>
              <BsPersonFill />
            </span>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{profile.name}</div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {profile.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/my-profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/profile-detail'} className='menu-link px-5'>
          Company Profile
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/settings'} className='menu-link px-5'>
          Settings
        </Link>
      </div>

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
