import {IAction} from '../store'
import {SHOW_MODAL_CONTACT_US} from './ContactUsActionTypes'

interface IContactUsState {
  modalShow: boolean
}

const initialState: IContactUsState = {
  modalShow: false,
}

const ContactUsReducer = (state = initialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case SHOW_MODAL_CONTACT_US:
      newState.modalShow = action.payload.show ? true : false
      break
    default:
      break
  }
  return newState
}

export default ContactUsReducer
