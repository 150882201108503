import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import {ConfirmProvider} from 'material-ui-confirm'
import ModalTopUp from '../../app/components/modals/ModalTopup'
import ModalCompany from './components/header/ModalCompany'
import ModalVerifyCompany from './components/header/ModalVerifyCompany'
import ModalContact from '../../app/components/contact-us/ModalContact'
import {useDispatch} from 'react-redux'
import {actShowModalContactUs} from '../../app/redux/contact-us/ContactUsActions'
import ModalSelectCreate from '../../app/pages/send-gifts/ModalSelectCreate'
// import { CompanyProvider } from '../../app/components/initializer/AuthCompany'

const MasterLayout = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    if (location.hash === '#contact_us') {
      dispatch(actShowModalContactUs())
    }
  }, [dispatch, location.hash])

  return (
    <PageDataProvider>
      {/* <CompanyProvider> */}
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div className='post d-flex flex-column' id='kt_post'>
              <Content>
                <ConfirmProvider>
                  <Outlet />
                </ConfirmProvider>
                <ModalVerifyCompany />
                <ModalTopUp />
                <ModalCompany />
                <ModalContact />
                <ModalSelectCreate />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {/* </CompanyProvider> */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export default MasterLayout
