import {useSelector} from 'react-redux'
import getSymbolFromCurrency from 'currency-symbol-map'
import {RootState} from '../redux/rootReducer'

const GetFormattedCurrency = ({value, locale}: {value: any; locale?: string}) => {
  // const {corporate_detail} = useSelector((state: RootState) => state.auth.company)
  // let localeValue = locale || corporate_detail['country']

  let options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  // if (localeValue === 'ID')
  //   options = {
  //     minimumFractionDigits: 0,
  //     maximumFractionDigits: 0,
  //   }
  return parseFloat(value).toLocaleString('en', options)
}

const CurrencySymbol = () => {
  const {corporate_detail} = useSelector((state: RootState) => state.auth.company)
  if (corporate_detail && corporate_detail['currency-code']) {
    return getSymbolFromCurrency(corporate_detail['currency-code'])
  } else {
    return ''
  }
}

export {GetFormattedCurrency, CurrencySymbol}
