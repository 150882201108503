import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { Alert, Button } from 'reactstrap'



const CreateAccountPage = () => {
  const params = new URLSearchParams(window.location.search)
  const paramAccount = params.get('account')
  const navigate = useNavigate()

  // Check if the screen size is below 1023px

  return (
    <div className='layoutWrapper create-account'>
      <Button color='link' className='text-primary d-lg-none ms-2 mt-2' onClick={() => navigate('/')}>
        <FaChevronLeft className='mr-2 text-primary' /> Back to Sign in page
      </Button>
      <div className=' row  business-content'>

        <div className='bg-account-container col-sm-12 col-md-12 col-lg-6'>
          <div className='bg-account bg-corporate '></div>
          <div className='cta-mobile d-lg-none'>
            <Link to={'/register'}>
              <Button className='mt-5 btn-rounded rounded-pill' color='primary'>
                Create Account Now
              </Button>
            </Link>

          </div>
        </div>
        <div className='login-block col-sm-12 col-md-12 col-lg-6'>
          <div className='login-wrapper' style={{ padding: '5px 15px', backgroundColor: '#ffffff' }}>
            <Button
              color='link'
              className='text-primary d-none d-lg-block'
              onClick={() => navigate('/')}
            >
              <FaChevronLeft className='mr-2 text-primary' /> Back to Sign in page
            </Button>
          </div>
          <div className='wrapper-login'>
            <div className='p-2 card'>
              <div className='card-body'>
                <div className='mb-3 row'>
                  <a href='https://giftano.com/'>
                    <img
                      src='https://giftano.imgix.net/assets/giftano-for-business-2x.png?auto=format'
                      className='auth-logo mx-auto d-block'
                      alt='Giftano logo'
                    />
                  </a>
                </div>
                <div>
                  {paramAccount === 'not-found' && (
                    <Alert color='warning' style={{ marginTop: '20px', fontSize: '18px' }}>
                      Sorry, we can’t find business account with your email address. Please create
                      Giftano Business Account or contact us at grow@giftano.com
                    </Alert>
                  )}
                  <div className='text-center'>
                    {/* <h4>Create an Account</h4> */}
                    <p>Welcome to Giftano for Business!</p>
                    {/* <p>Click the button below to create an account.</p> */}
                    <Link className='d-none d-lg-block' to={'/register'}>
                      <Button className='mt-5 btn-rounded rounded-pill' color='primary'>
                        Create Account Now
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAccountPage
