import React, {FC} from 'react'
import CurrencyInputField, {CurrencyInputProps} from 'react-currency-input-field'
import {useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'

const CurrencyInput: FC<CurrencyInputProps> = (props) => {
  const {corporate_detail} = useSelector((state: RootState) => state.auth.company)
  return (
    <CurrencyInputField
      prefix={corporate_detail['currency-symbol']}
      decimalSeparator={'.'}
      groupSeparator={','}
      className={'form-control'}
      {...props}
    />
  )
}

export default CurrencyInput
