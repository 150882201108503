import {IAction} from '../store'
import {APP_SET_LANG} from './AppActionTypes'

interface IAppState {
  lang: string
}

const InitialState: IAppState = {
  lang: 'en',
}

const AppReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case APP_SET_LANG:
      newState.lang = action.payload.lang
      break
    default:
  }
  return newState
}

export default AppReducer
