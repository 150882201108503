import {IAction} from '../store'
import {ERROR_SOABALANCE, LOADING_SOABALANCE, SUCCESS_SOABALANCE} from './SoaBalanceTypes'

interface ICatalogue {
  data: {credits: 0}
  status: 'pending' | 'loading' | 'success' | 'error' | 'default'
  error?: string
}

const InitialState: ICatalogue = {
  data: {
    credits: 0,
  },
  status: 'default',
}

const SoaBalanceReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}

  switch (action.type) {
    case LOADING_SOABALANCE:
      newState.status = 'loading'
      break
    case SUCCESS_SOABALANCE:
      newState.data = action.payload.data
      newState.status = 'success'
      break
    case ERROR_SOABALANCE:
      newState.status = 'error'
      break
    default:
      break
  }

  return newState
}

export default SoaBalanceReducer
