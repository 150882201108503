import {IAction} from '../store'
import {
  ADD_CREDIT_CARD,
  DELETE_CREDIT_CARD,
  ERROR_CREDIT_CARD,
  LOADING_CREDIT_CARD,
  RELOAD_CREDIT_CARD,
  SUCCESS_CREDIT_CARD,
} from './CreditCardActionTypes'

export interface ICreditCardState {
  data: any[]
  status: 'pending' | 'loading' | 'success' | 'error'
  error?: string
}

const InitialState: ICreditCardState = {
  data: [],
  status: 'pending',
}

const CreditCardReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_CREDIT_CARD:
      newState.status = 'loading'
      break
    case SUCCESS_CREDIT_CARD:
      newState.status = 'success'
      newState.data = action.payload.data
      break
    case ERROR_CREDIT_CARD:
      newState.status = 'error'
      break
    case ADD_CREDIT_CARD:
      newState.data = action.payload.data
      break
    case DELETE_CREDIT_CARD:
      newState.data = newState.data.filter((i) => i.Token !== action.payload.token)
      break
    case RELOAD_CREDIT_CARD:
      newState.status = 'pending'
      break
    default:
  }
  return newState
}

export default CreditCardReducer
