import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import AuthInit from './components/initializer/AuthInit'

// import "bootstrap/dist/css/bootstrap.min.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import 'react-datepicker/dist/react-datepicker.css'
import 'react-phone-input-2/lib/style.css'
import './styles/App.scss'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
