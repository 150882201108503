import {IAction} from '../store'
import {
  ADD_ITEM_CORPORATE_ORDER_DRAFT,
  DELETE_ITEM_CORPORATE_ORDER_DRAFT,
  ERROR_CORPORATE_ORDER_DRAFT,
  LOADING_CORPORATE_ORDER_DRAFT,
  RELOAD_CORPORATE_ORDER_DRAFT,
  SUCCESS_CORPORATE_ORDER_DRAFT,
  UPDATE_ITEM_CORPORATE_ORDER_DRAFT,
} from './CorporateActionTypes'

export interface IDraftOrderState {
  data: any[]
  status: 'pending' | 'loading' | 'success' | 'error'
  error?: string
  formData?: any
}

const InitialState: IDraftOrderState = {
  data: [],
  status: 'pending',
}

const DraftOrderReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_CORPORATE_ORDER_DRAFT:
      newState.status = 'loading'
      break
    case SUCCESS_CORPORATE_ORDER_DRAFT:
      newState.status = 'success'
      newState.data = action.payload.data
      break
    case ERROR_CORPORATE_ORDER_DRAFT:
      newState.status = 'error'
      break
    case ADD_ITEM_CORPORATE_ORDER_DRAFT:
      newState.data.unshift({...action.payload.data})
      break
    case UPDATE_ITEM_CORPORATE_ORDER_DRAFT:
      newState.data = newState.data.map((i) =>
        i.id === action.payload.id ? {id: action.payload.id, ...action.payload.data} : {...i}
      )
      break
    case DELETE_ITEM_CORPORATE_ORDER_DRAFT:
      newState.data = newState.data.filter((i) => i.id !== action.payload.id)
      break
    case RELOAD_CORPORATE_ORDER_DRAFT:
      newState.status = 'pending'
      break
    default:
  }
  return newState
}

export default DraftOrderReducer
