import {CONFIG, CONNECTION} from '../../helper'

const endpoint = process.env.REACT_APP_ENDPOINT_APP_MANAGEMENT
const appName = process.env.REACT_APP_NAME
export const getRoutes = async () => {
  const config = CONFIG({
    url: endpoint + '/routes/' + appName + '?by=slug',
  })
  return await CONNECTION(config)
}
