import {IAction} from '../store'
import {
  DEFAULT_USERMANAGEMENT,
  ERROR_USERMANAGEMENT,
  EXSITS_USERMANAGEMENT,
  GET_USERMANAGEMENT,
  LOADING_USERMANAGEMENT,
  NOTEXSITS_USERMANAGEMENT,
  RELOAD_USERMANAGEMENT,
  SUCCESS_USERMANAGEMENT,
} from './UserManagementTypes'

interface IAState {
  status: 'pending' | 'loading' | 'success' | 'error' | 'exsits' | 'default' | 'not-exsist'
  data?: any[]
  message?: any
}

const initailState: IAState = {
  status: 'pending',
  data: [],
}

const UserManagement = (state = initailState, action: IAction) => {
  let response = {...state}
  switch (action.type) {
    case RELOAD_USERMANAGEMENT:
      response.status = 'pending'
      break
    case SUCCESS_USERMANAGEMENT:
      response.data = action.payload.data
      response.status = 'success'
      break
    case EXSITS_USERMANAGEMENT:
      response.status = 'exsits'
      break
    case NOTEXSITS_USERMANAGEMENT:
      response.status = 'not-exsist'
      break
    case ERROR_USERMANAGEMENT:
      response.status = 'error'
      break
    case GET_USERMANAGEMENT:
      response.status = 'success'
      break
    case LOADING_USERMANAGEMENT:
      response.status = 'loading'
      break
    case DEFAULT_USERMANAGEMENT:
      response.status = 'default'
      break
    default:
  }

  return response
}

export default UserManagement
