import {SUCCESS_MODAL} from './ModalTypes'

interface IAppState {
  modal: string
  data: any
}

const InitialState: IAppState = {
  modal: 'OFF',
  data: {},
}

const InitialAction = {
  type: '',
  modal: '',
  data: {},
  open: false,
}

const ModalReducer = (state = InitialState, action = InitialAction) => {
  let newState = {...state}

  switch (action.type) {
    case SUCCESS_MODAL:
      newState = {
        modal: action.modal,
        data: action.data || {},
        [action.modal]: action.open,
      }
      break
    default:
  }

  return newState
}

export default ModalReducer
