import {GiButton, GiModal, GiModalBody, GiModalHeader} from '../../components/system-ui'
import RedempPrize from '../../assets/images/redeem-prize.svg'
import basicMethod from '../../assets/images/basic-method.svg'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import {actHideModalSelectCreateGift} from '../../redux/select-create-gift/SelectCreateGiftActions'
import './ModalSelectCreate.scss'
import {useNavigate} from 'react-router-dom'

const ModalSelectCreate = () => {
  const selectCreateGift = useSelector((state: RootState) => state.selectCreateGift)
  const dispatch = useDispatch()
  const toggle = () => dispatch(actHideModalSelectCreateGift())
  const navigate = useNavigate()
  const strapiData = useSelector((state: RootState) => state.strapi.data)

  return (
    <GiModal isOpen={selectCreateGift.modalShow} size='lg' toggle={toggle} backdrop='static'>
      <GiModalHeader toggle={toggle}>
        <img alt='Logo' width={50} height={50} src={RedempPrize} className='me-4' />
        <span style={{verticalAlign: 'sub'}}>Create Gift</span>
      </GiModalHeader>
      <GiModalBody>
        <div className='d-flex gap-7'>
          {strapiData &&
            strapiData.create_orders_method &&
            strapiData.create_orders_method.map(
              (item: any, index: number) =>
                item.is_active && (
                  <div
                    key={index}
                    className='box-create-opsi d-flex flex-column h-100'
                    style={{minHeight: '303px'}}
                    onClick={() => {
                      toggle()
                      if (item.url) {
                        navigate(item.url)
                      } else {
                        if (item.slug === 'basic') navigate('/send-gifts/create')
                        if (item.slug === 'advance') navigate('/send-gifts/create-campaign')
                        if (item.slug === 'csv') navigate('/send-gifts/create-with-csv')
                      }
                    }}
                  >
                    <img
                      src={item.icon.url || basicMethod}
                      alt={item.title}
                      height={'85px'}
                      style={{alignSelf: 'center'}}
                    />
                    <p>{item.desc}</p>
                    <GiButton
                      className='rounded-pill mt-auto'
                      style={{alignSelf: 'center'}}
                      color='primary'
                    >
                      {item.title}
                    </GiButton>
                  </div>
                )
            )}

          {/* <div className='box-create-opsi flex-column'>
            <img src={basicMethod} alt='' height={'85px'} />
            <p>Send gift to one or many people.</p>
            <GiButton
              className='rounded-pill mt-auto'
              color='primary'
              onClick={() => {
                toggle()
                navigate('/send-gifts/create')
              }}
            >
              Basic
            </GiButton>
          </div> */}
          {/* <div className='box-create-opsi flex-column'>
            <img src={advanMethod} alt='' height={'85px'} />
            <p>Send different gift with different message and time to one or many people.</p>
            <GiButton
              className='rounded-pill mt-auto'
              color='primary'
              onClick={() => {
                toggle()
                navigate('/send-gifts/create-campaign')
              }}
            >
              Advance
            </GiButton>
          </div>
          <div className='box-create-opsi flex-column'>
            <img src={csvMethod} alt='' height={'85px'} />
            <p>
              Send different gift with different message and time to one or many people. Ideal to
              send mass gifts
            </p>
            <GiButton
              className='rounded-pill mt-auto'
              color='primary'
              onClick={() => {
                toggle()
                navigate('/send-gifts/create-with-csv')
              }}
            >
              CSV
            </GiButton>
          </div> */}
        </div>
      </GiModalBody>
    </GiModal>
  )
}

export default ModalSelectCreate
