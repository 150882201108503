import React, {FC, useState} from 'react'
import RichTextEditor from 'react-rte'

const GftRichTextEditor: FC<{defaultValue: string; onChange?: (value: string) => void}> = (
  props
) => {
  const [value, setValue] = useState(
    props.defaultValue
      ? RichTextEditor.createValueFromString(props.defaultValue, 'html')
      : RichTextEditor.createEmptyValue()
  )

  const onChange = (value: any) => {
    setValue(value)
    if (props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      props.onChange(value.toString('html'))
    }
  }

  // useEffect(() => {
  //   setValue(
  //     props.defaultValue
  //       ? RichTextEditor.createValueFromString(props.defaultValue, "html")
  //       : RichTextEditor.createEmptyValue()
  //   );
  // }, [props.defaultValue]);

  return <RichTextEditor {...props} value={value} onChange={onChange} />
}

export default GftRichTextEditor
