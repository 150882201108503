import {createSlice} from '@reduxjs/toolkit'

interface IGreetingCategoryState {
  data: any[]
  status: 'pending' | 'loading' | 'success' | 'error'
}

const initialState: IGreetingCategoryState = {
  data: [],
  status: 'pending',
}

const GreetingCategorySlice = createSlice({
  name: 'greeting-catagory',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.status = 'loading'
    },
    setSuccess: (state, action) => {
      state.data = action.payload.data || []
      state.status = 'success'
    },
    setError: (state) => {
      state.status = 'error'
    },
  },
})

export const {setLoading, setSuccess, setError} = GreetingCategorySlice.actions

export const GreetingCategoryReducer = GreetingCategorySlice.reducer

export default GreetingCategoryReducer
