import {Dispatch} from 'redux'
import {getRoutes} from '../../services'
import {IAction} from '../store'
import {ERROR_ROUTE, LOADING_ROUTE, SUCCESS_ROUTE} from './RouteActionTypes'
import * as Sentry from '@sentry/react'

export const getRoutesAct = () => async (dispatch: Dispatch<IAction>) => {
  dispatch({type: LOADING_ROUTE})
  try {
    const resp = await getRoutes()
    if (resp.code === 200) {
      dispatch({type: SUCCESS_ROUTE, payload: {data: resp.data}})
    } else {
      dispatch({type: ERROR_ROUTE, payload: {error: resp.error}})
    }
  } catch (err) {
    Sentry.captureException(err)
    dispatch({type: ERROR_ROUTE})
  }
}
