import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {actHideModalContactUs} from '../../redux/contact-us/ContactUsActions'
import {RootState} from '../../redux/rootReducer'
import {SendEmail} from '../../services/email/EmailServices'
import GftForm from '../form/GftForm'
import {GiButton, GiCol, GiModal, GiModalBody, GiModalHeader, GiRow} from '../system-ui'
import * as Sentry from '@sentry/react'

const ModalContact = () => {
  const {corporate_detail} = useSelector((state: RootState) => state.auth.company)
  const user_info = useSelector((state: RootState) => state.auth.user_info)
  const contactUs = useSelector((state: RootState) => state.contactUs)
  const dispatch = useDispatch()
  const toggle = () => dispatch(actHideModalContactUs())

  const [submitted, setSubmitted] = useState(false)
  return (
    <GiModal isOpen={contactUs.modalShow} toggle={toggle} autoFocus={false}>
      <GiModalHeader toggle={toggle}>Enquiry</GiModalHeader>
      <GiModalBody>
        {submitted ? (
          <div className='text-center'>
            <h4>Thank you for getting in touch!</h4>
            <p>
              We appreciate you contacting us. One of our teams will get back in touch with you
              soon! Have a great day!
            </p>
          </div>
        ) : (
          <GftForm
            fields={[
              {
                type: 'text',
                name: 'name',
                label: 'Name *',
                validation: ['required'],
                value: user_info.full_name || '',
                groupProps: {row: true},
                labelProps: {md: 4},
                inputColProps: {md: 8},
                inputProps: {
                  autoFocus: true,
                },
              },
              {
                type: 'email',
                name: 'email',
                label: 'Email *',
                validation: ['required'],
                value: user_info.email || '',
                groupProps: {row: true},
                labelProps: {md: 4},
                inputColProps: {md: 8},
              },
              {
                type: 'phone',
                name: 'phone',
                label: 'Phone',
                value: user_info.phone_number || '',
                groupProps: {row: true},
                labelProps: {md: 4},
                inputColProps: {md: 8},
              },
              {
                type: 'textarea',
                name: 'message',
                label: 'Message *',
                validation: ['required'],
                groupProps: {row: true},
                labelProps: {md: 4},
                inputColProps: {md: 8},
                inputProps: {
                  rows: 5,
                },
              },
            ]}
            submitButton={(props, isSubmitting) => (
              <GiRow>
                <GiCol md={{size: 3, offset: 4}}>
                  <GiButton
                    className='rounded-pill mt-4'
                    type={'submit'}
                    disabled={isSubmitting}
                    color={'primary'}
                  >
                    Submit
                  </GiButton>
                </GiCol>
              </GiRow>
            )}
            onSubmit={async (values) => {
              // console.log(values);
              try {
                let resp = await SendEmail({
                  from_email: 'grow@giftano.com',
                  recipients: [
                    {
                      email:
                        process.env.REACT_APP_BUILD_MODE === 'PRODUCTION'
                          ? 'grow@giftano.com'
                          : 'dev_test_email_grow@giftano.com',
                    },
                  ],
                  subject: 'Enquiry from giftano business ' + corporate_detail.name,
                  html_tag_content: `<strong>Name :</strong> ${values.name}<br/>
                                    <strong>Email :</strong> ${values.email}<br/>
                                    <strong>Phone Number :</strong> ${values.phone}<br/>
                                    <strong>Corporate Name :</strong> ${corporate_detail.name}<br/>
                                    <strong>Message :</strong> <br/>
                                    ${values.message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
                })
                if (resp.code === 200) {
                  setSubmitted(true)
                }
              } catch (err) {
                Sentry.captureException(err)
                console.log(err)
              }
            }}
          />
        )}
      </GiModalBody>
    </GiModal>
  )
}

export default ModalContact
