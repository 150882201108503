import {Dispatch} from 'redux'
import {getAllContentByTag} from '../../services/content/ContentServices'
import {IAction} from '../store'
import {LOADING_APP_STRING, SUCCESS_APP_STRING} from './AppStringActionTypes'
import * as Sentry from '@sentry/react'

export const actGetAppString = () => async (dispatch: Dispatch<IAction>) => {
  dispatch({type: LOADING_APP_STRING})
  try {
    let res = await getAllContentByTag('fe-app-string-business-dashboard')
    if (res.code === 200 && res.data) {
      //map data into obj
      let newData: any = {}
      for (let item of res.data) {
        newData[item.slug] = item.descriptions
      }
      dispatch({type: SUCCESS_APP_STRING, payload: {data: newData}})
    }
  } catch (err) {
    Sentry.captureException(err)
  }
}
