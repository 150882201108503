import {Dispatch} from 'redux'
import {IAction} from '../store'
import {SHOW_MODAL_CONTACT_US} from './ContactUsActionTypes'

export const actShowModalContactUs = () => (dispatch: Dispatch<IAction>) =>
  dispatch({
    type: SHOW_MODAL_CONTACT_US,
    payload: {show: true},
  })
export const actHideModalContactUs = () => (dispatch: Dispatch<IAction>) =>
  dispatch({
    type: SHOW_MODAL_CONTACT_US,
    payload: {show: false},
  })
