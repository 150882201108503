import {IAction} from '../store'
import {LOADING_FINANCE_GROUP, SUCCESS_FINANCE_GROUP} from './FinanceGroupTypes'

export interface IFiananceGroup {
  data: any[]
  status: 'default' | 'loading' | 'success' | 'error'
  error?: string
}
const initialState: IFiananceGroup = {
  data: [],
  status: 'default',
}

const FinanceGroupReducer = (state = initialState, action: IAction) => {
  const newState = {...state}

  switch (action.type) {
    case SUCCESS_FINANCE_GROUP:
      newState.data = action.payload.data
      newState.status = 'success'
      break
    case LOADING_FINANCE_GROUP:
      newState.data = []
      newState.status = 'loading'
      break
    default:
      break
  }

  return newState
}

export default FinanceGroupReducer
