import {Dispatch} from 'react'
import * as ModalTypes from './ModalTypes'

interface InitialParams {
  data?: any
  modal: string
  open: boolean
}

const ParamsModal: InitialParams = {
  modal: '',
  open: true,
  data: {},
}

export const getModal =
  (params = ParamsModal) =>
  (dispatch: Dispatch<any>) => {
    return dispatch({
      type: ModalTypes.SUCCESS_MODAL,
      modal: params.modal,
      open: params.open,
      data: params.data,
    })
  }
