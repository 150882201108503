export const LOADING_CORPORATE_ORDER_DRAFT = 'LOADING_CORPORATE_ORDER_DRAFT'
export const SUCCESS_CORPORATE_ORDER_DRAFT = 'SUCCESS_CORPORATE_ORDER_DRAFT'
export const ERROR_CORPORATE_ORDER_DRAFT = 'ERROR_CORPORATE_ORDER_DRAFT'
export const SETFORMDATA_CORPORATE_ORDER_DRAFT = 'SETFORMDATA_CORPORATE_ORDER_DRAFT'
export const ADD_ITEM_CORPORATE_ORDER_DRAFT = 'ADD_ITEM_CORPORATE_ORDER_DRAFT'
export const UPDATE_ITEM_CORPORATE_ORDER_DRAFT = 'UPDATE_ITEM_CORPORATE_ORDER_DRAFT'
export const DELETE_ITEM_CORPORATE_ORDER_DRAFT = 'DELETE_ITEM_CORPORATE_ORDER_DRAFT'
export const RELOAD_CORPORATE_ORDER_DRAFT = 'RELOAD_CORPORATE_ORDER_DRAFT'

export const EXIST_CORPORATE = 'EXIST_CORPORATE'
export const LOADING_CORPORATE = 'LOADING_CORPORATE'
export const SUCCESS_CORPORATE = 'SUCCESS_CORPORATE'
export const ERROR_CORPORATE = 'ERROR_CORPORATE'

export const LOADING_ACCOUNT_BALANCE = 'LOADING_ACCOUNT_BALANCE'
export const SUCCESS_ACCOUNT_BALANCE = 'SUCCESS_ACCOUNT_BALANCE'
export const ERROR_ACCOUNT_BALANCE = 'ERROR_ACCOUNT_BALANCE'
export const SHOW_TOPUP_ACCOUNT_BALANCE = 'SHOW_TOPUP_ACCOUNT_BALANCE'
export const RELOAD_ACCOUNT_BALANCE = 'RELOAD_ACCOUNT_BALANCE'
export const SET_MODAL_DATA_ACCOUNT_BALANCE = 'SET_MODAL_DATA_ACCOUNT_BALANCE'
