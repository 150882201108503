/* eslint-disable eqeqeq */
/* ---
   manual
   https://docs.google.com/document/d/1bZdV1fqMPPSW9rAerUiBPTb8wzerlZ6CAwGWgTCY5NE/edit?usp=sharing
*/

import React, {FC} from 'react'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  FormText,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonProps,
  CardTitle,
} from 'reactstrap'
import {Formik, FormikValues} from 'formik'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CurrencyInput from './CurrencyInput'
import {BsCloudUpload} from 'react-icons/bs'
import {AxiosRequestConfig, Method} from 'axios'
import {toast} from 'react-toastify'
import {Card, CardBody, CardHeader} from 'reactstrap'
import GftRichTextEditor from './GftRichTextEditor'
// import DropzoneComponent from 'react-dropzone-component';
// @ts-ignore
import {JsonEditor} from 'jsoneditor-react'
import {StrapiThumbnail} from './StrapiImage'
import {CONFIG, CONFIG_CP, CONFIG_STRAPI_CONTENT, CONNECTION} from '../../helper'
import {useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
// import { STRAPIEXTRA_BASEURL, STRAPIEXTRA_TOKEN } from '../../config/Connections';

const baseUrlLegacy = process.env.REACT_APP_BASEURL_LEGACY || ''
const baseUrlContent = process.env.REACT_APP_BASEURL_CONTENT || ''

const gftStyle = {
  formCheck: {
    paddingLeft: '0px',
    marginBottom: '16px',
  },
  imgPreview: {
    height: '100px',
    boxShadow: 'var(--gf-shadow-normal)',
    padding: '1em',
    borderRadius: 'var(--gf-border-radius-m)',
    border: '1px solid var(--gf-neutral-200)',
  },
}

export interface IGftField {
  type?:
    | 'text'
    | 'email'
    | 'password'
    | 'number'
    | 'phone'
    | 'currency'
    | 'textarea'
    | 'select'
    | 'checkbox'
    | 'datepicker'
    | 'rte'
    | 'strapi-image'
    | 'file'
    | 'hidden'
    | 'json'
    | 'clearfix'
    | 'custom'
  name: string
  label?: string
  placeholder?: string
  help?: string
  value?: any
  validation?: string[]
  options?: any[]
  onChange?: (
    value: any,
    values: FormikValues,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => void
  colProps?: {xs?: number; sm?: number; md: number; lg?: number; xl?: number}
  inputProps?: any
  if?: (values: FormikValues) => boolean
  valueFormatter?: (value: any, values: FormikValues) => any
  groupProps?: any
  labelProps?: any
  inputColProps?: any
  countrycodeEditable?: boolean
  customElement?: (
    value: any,
    values: FormikValues,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    isSubmitting: boolean
  ) => JSX.Element
}

export interface IGftFormProps {
  variant?: 'div' | 'page' | 'modal'
  containerProps?: any
  isOpen?: boolean
  toggle?: () => void
  size?: 'sm' | 'md' | 'lg'
  title?: string
  fields: IGftField[]
  initialValues?: any
  action?: string
  method?: Method
  dataType?: string
  beforeSend?: (values: FormikValues) => FormikValues
  onSuccess?: (response: any) => void
  onSubmit?: (values: FormikValues, formikBag: any) => void | Promise<any>
  validate?: (values: FormikValues) => object
  formId?: string
  cancelButton?: (props: ButtonProps, isSubmitting: boolean) => JSX.Element
  submitButton?: (props: ButtonProps, isSubmitting: boolean) => JSX.Element
}

const GftForm: FC<IGftFormProps> = (props) => {
  const strapiToken: string = useSelector((state: RootState) => state.auth.strapi_token) || ''

  //grab initial values from fields key value
  let initialValues: any = {}
  props.fields.forEach((field) => {
    initialValues[field.name] = field.value || ''
  })
  if (props.initialValues) {
    // join initial values from field and form props
    initialValues = {...initialValues, ...props.initialValues}
  }

  //validation function from field validation key value
  //can override by props.validateion and return errors obj
  const validationFunc = (values: any) => {
    let errors: any = {}
    //validate each field
    props.fields.forEach((field) => {
      if (field.validation) {
        if (
          field.validation.includes('required') &&
          (!values[field.name] ||
            (Array.isArray(values[field.name]) && values[field.name].length === 0))
        ) {
          errors[field.name] = `${field.label || field.name} can not be empty`
        } else if (
          field.validation.includes('email') &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[field.name])
        ) {
          errors[field.name] = `Invalid email format`
        }
      }
    })
    if (props.validate) {
      errors = {...errors, ...props.validate(values)}
    }
    return errors
  }

  //default submit function
  //need to define props action
  //can overide by onSubmit props
  const submitFunc = async (values: FormikValues) => {
    if (props.action) {
      //overide values before send
      if (props.beforeSend) {
        values = props.beforeSend(values)
      }
      //add to formdata if dataType === formdata
      const fd = new FormData()
      Object.keys(values).forEach((k) => {
        if (values[k]) {
          if (Array.isArray(values[k])) {
            values[k].forEach((vv: any) => {
              fd.append(k, vv)
            })
          } else {
            fd.append(k, values[k])
          }
        }
      })
      let config: AxiosRequestConfig
      if (props.action.substring(0, baseUrlLegacy.length) === baseUrlLegacy) {
        config = CONFIG_CP({
          url: props.action,
          method: props.method || 'POST',
          data: props.dataType === 'formdata' ? fd : values,
        })
      } else if (props.action.substring(0, baseUrlContent.length) === baseUrlContent) {
        config = CONFIG_STRAPI_CONTENT(
          {
            url: props.action,
            method: props.method || 'POST',
            data: props.dataType === 'formdata' ? fd : values,
          },
          strapiToken
        )
      } else {
        config = CONFIG({
          url: props.action,
          method: props.method || 'POST',
          data: props.dataType === 'formdata' ? fd : values,
        })
      }
      // console.log(config)

      //send to server
      const resp = await CONNECTION(config)
      // legacy return status 1 for success
      // new platform return code 200 for update and 201 for created
      if (resp.status === 1 || resp.code === 200 || resp.code === 201) {
        toast.success('Data saved successfully')
        if (props.variant === 'modal' && props.toggle) {
          props.toggle()
        }
        if (props.onSuccess) {
          props.onSuccess(resp.data)
        }
      } else {
        toast.warning(resp.data.message || 'data was not saved successfully')
      }
    }
  }

  // default container
  let CustomContainer: any = ({children}: any) => <div>{children}</div>

  // if container == modal dialog
  if (props.variant && props.variant === 'modal') {
    CustomContainer = ({children, isSubmitting}: any) => (
      <Modal
        isOpen={props.isOpen}
        fade
        toggle={props.toggle}
        autoFocus={false}
        size={props.size}
        centered
      >
        <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {props.submitButton ? (
            props.submitButton(
              {
                form: props.formId || 'gftForm',
                type: 'submit',
                color: 'primary',
                disabled: isSubmitting,
              },
              isSubmitting
            )
          ) : (
            <Button
              disabled={isSubmitting}
              form={props.formId || 'gftForm'}
              type='submit'
              color='primary'
              size='sm'
            >
              {isSubmitting ? (
                <i className='fa fa-spinner fa-spin'></i>
              ) : (
                <i className='fa fa-save'></i>
              )}
              Save
            </Button>
          )}
          {props.cancelButton ? (
            props.cancelButton({onClick: props.toggle}, isSubmitting)
          ) : (
            <Button onClick={props.toggle} size='sm'>
              Close
            </Button>
          )}
        </ModalFooter>
      </Modal>
    )
  }

  // if container == page
  if (props.variant && props.variant === 'page') {
    CustomContainer = ({children}: any) => (
      <Card>
        {props.title && (
          <CardHeader>
            <CardTitle>{props.title}</CardTitle>
          </CardHeader>
        )}
        <CardBody>{children}</CardBody>
      </Card>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validate={validationFunc}
      onSubmit={props.onSubmit || submitFunc}
    >
      {({values, errors, touched, handleBlur, handleSubmit, isSubmitting, setFieldValue}) => (
        <Form id={props.formId || 'gftForm'} onSubmit={handleSubmit}>
          <CustomContainer isSubmitting={isSubmitting}>
            <Row {...props.containerProps}>
              {props.fields.map((field, idx) => {
                if (field.if) {
                  if (field.if(values) === false) {
                    return null
                  }
                }
                if (field.type === 'clearfix')
                  return <div key={'form_field_' + idx} className='col-12 col-xs-12'></div>
                let value = field.valueFormatter
                  ? field.valueFormatter(values[field.name], values)
                  : values[field.name]
                if (field.type === 'datepicker') {
                  if (value) {
                    try {
                      value = new Date(value)
                    } catch (err) {
                      value = undefined
                    }
                  } else {
                    value = undefined
                  }
                } else if (field.type === 'select') {
                  if (field.inputProps && field.inputProps.isMulti) {
                    value =
                      field.options && value
                        ? field.options.filter((i) => value.includes(i.value))
                        : []
                  } else {
                    value =
                      field.options && value
                        ? field.options.filter((i) => i.value == value)[0]
                        : undefined
                  }
                }
                let onChange = (val: any) =>
                  field.onChange
                    ? field.onChange(val, values, setFieldValue)
                    : setFieldValue(field.name, val)
                if (field.type === 'hidden')
                  return (
                    <input
                      key={'form_field_' + idx}
                      type='hidden'
                      name={field.name}
                      value={value}
                    />
                  )
                return (
                  <Col {...field.colProps} xs='12' key={'form_field_' + idx}>
                    <FormGroup {...field.groupProps}>
                      <Label {...field.labelProps}>{field.label}</Label>
                      <Col {...field.inputColProps}>
                        {field.type === 'phone' ? (
                          <PhoneInput
                            name={field.name}
                            country={'sg'}
                            value={value}
                            onChange={(phone_number) => onChange(phone_number)}
                            onBlur={handleBlur}
                            enableSearch
                            autoFormat={false}
                            countryCodeEditable={field.countrycodeEditable ?? false}
                            {...field.inputProps}
                          />
                        ) : field.type === 'select' ? (
                          <Select
                            options={field.options}
                            name={field.name}
                            value={value}
                            onChange={(evt: any) => {
                              if (field.inputProps && field.inputProps.isMulti) {
                                onChange(evt ? evt.map((e: any) => e.value) : [])
                              } else {
                                onChange(evt ? evt.value : undefined)
                              }
                            }}
                            onBlur={handleBlur}
                            {...field.inputProps}
                          />
                        ) : field.type === 'datepicker' ? (
                          <div className='form-field-datepicker'>
                            <DatePicker
                              name={field.name}
                              className={`form-control`}
                              selected={value}
                              onChange={(date) => onChange(date)}
                              onBlur={handleBlur}
                              autoComplete='off'
                              {...field.inputProps}
                            />
                          </div>
                        ) : field.type === 'currency' ? (
                          <CurrencyInput
                            name={field.name}
                            value={value}
                            onValueChange={(value: any) => onChange(value)}
                            onBlur={handleBlur}
                            {...field.inputProps}
                          />
                        ) : field.type === 'rte' ? (
                          <GftRichTextEditor
                            className={'rte-height-200'}
                            name={field.name}
                            defaultValue={value}
                            onChange={(value: any) => onChange(value)}
                            onBlur={handleBlur}
                            {...field.inputProps}
                          />
                        ) : field.type === 'checkbox' ? (
                          field.options &&
                          field.options.map((opt, optIdx) => (
                            <FormGroup key={'optIdx_' + optIdx} check {...field.inputProps}>
                              <Label key={'optCheck_' + optIdx} check>
                                <Input
                                  type='checkbox'
                                  name={field.name}
                                  value={opt.value}
                                  checked={
                                    Array.isArray(value)
                                      ? value.includes(opt.value)
                                      : value == opt.value
                                  }
                                  onChange={(e) => {
                                    let newValue: any[] = []
                                    if (Array.isArray(value)) {
                                      newValue = [...value]
                                    }
                                    if (e.target.checked) {
                                      newValue.push(opt.value)
                                    } else {
                                      newValue = newValue.filter((i) => i !== opt.value)
                                    }
                                    //remove undefined value
                                    newValue = newValue.filter((i) => i)
                                    onChange(newValue)
                                  }}
                                />{' '}
                                {opt.label}
                              </Label>
                            </FormGroup>
                          ))
                        ) : field.type === 'file' ? (
                          <>
                            {value && (
                              <div className='mb-4 ' style={{overflow: 'hide'}}>
                                <img
                                  style={gftStyle.imgPreview}
                                  src={
                                    value.substr && value.substr(0, 4) === 'http'
                                      ? value
                                      : URL.createObjectURL(value)
                                  }
                                  alt='Preview'
                                />
                              </div>
                            )}
                            <Button
                              variant='warning'
                              className='rounded-pill'
                              onClick={() => {
                                const el: HTMLElement = document.querySelector(
                                  '#__file_' + field.name
                                ) as HTMLElement
                                if (el) {
                                  el.click()
                                }
                              }}
                            >
                              <BsCloudUpload className='me-2' />
                              Choose Image
                            </Button>
                            <input
                              {...field.inputProps}
                              id={'__file_' + field.name}
                              type='file'
                              style={{display: 'none'}}
                              onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                  onChange(e.target.files[0])
                                }
                              }}
                            />
                          </>
                        ) : field.type === 'strapi-image' ? (
                          <StrapiThumbnail value={value} onChange={(val: any) => onChange(val)} />
                        ) : field.type === 'json' ? (
                          <JsonEditor
                            mode='code'
                            value={value ? JSON.parse(value) : ''}
                            onChange={(val: any) => onChange(JSON.stringify(val))}
                          />
                        ) : field.type === 'custom' ? (
                          field.customElement &&
                          field.customElement(value, values, setFieldValue, isSubmitting)
                        ) : (
                          <Input
                            type={field.type}
                            name={field.name}
                            value={value}
                            onChange={(evt) => onChange(evt.target.value)}
                            onBlur={handleBlur}
                            invalid={errors[field.name] && touched[field.name] ? true : false}
                            {...field.inputProps}
                          />
                        )}
                        {field.help && <FormText>{field.help}</FormText>}
                        {errors[field.name] && touched[field.name] && (
                          <FormFeedback valid={false} style={{display: 'block'}}>
                            {errors[field.name]}
                          </FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                  </Col>
                )
              })}
            </Row>
            {props.variant !== 'modal' && (
              <div className='form-button-action text-right'>
                {props.submitButton ? (
                  props.submitButton(
                    {
                      disabled: isSubmitting,
                      type: 'submit',
                      color: 'primary',
                    },
                    isSubmitting
                  )
                ) : (
                  <Button disabled={isSubmitting} type='submit' size='sm' color='primary'>
                    {isSubmitting ? (
                      <i className='fa fa-spinner fa-spin'></i>
                    ) : (
                      <i className='fa fa-save'></i>
                    )}
                    Save
                  </Button>
                )}
                {props.cancelButton &&
                  props.cancelButton({className: 'ml-2', color: 'danger'}, isSubmitting)}
              </div>
            )}
          </CustomContainer>
        </Form>
      )}
    </Formik>
  )
}

export default GftForm
