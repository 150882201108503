import {Dispatch} from 'redux'
import {IAction} from '../store'
import {SHOW_MODAL_SELECT_CREATE_GIFT} from './SelectCreateGiftTypes'

export const actShowModalSelectCreateGift = () => (dispatch: Dispatch<IAction>) =>
  dispatch({
    type: SHOW_MODAL_SELECT_CREATE_GIFT,
    payload: {show: true},
  })
export const actHideModalSelectCreateGift = () => (dispatch: Dispatch<IAction>) =>
  dispatch({
    type: SHOW_MODAL_SELECT_CREATE_GIFT,
    payload: {show: false},
  })
