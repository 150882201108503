import {IAction} from '../store'
import {ERROR_SOAINOVOICE, LOADING_SOAINOVOICE, SUCCESS_SOAINOVOICE} from './SoaInvoiceTypes'

interface ICatalogue {
  data: {}
  status: 'pending' | 'loading' | 'success' | 'error' | 'default'
  error?: string
}

const InitialState: ICatalogue = {
  data: {},
  status: 'default',
}

const SoaInvoiceReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}

  switch (action.type) {
    case LOADING_SOAINOVOICE:
      newState.status = 'loading'
      break
    case SUCCESS_SOAINOVOICE:
      newState.data = action.payload.data
      newState.status = 'success'
      break
    case ERROR_SOAINOVOICE:
      newState.status = 'error'
      break
    default:
      break
  }

  return newState
}

export default SoaInvoiceReducer
