import {IAction} from '../store'
import {DEFAULT_TRANSFER, LOADING_TRANSFER, SUCCESS_TRANSFER} from './TransferActionTypes'

export interface ITransferCredit {
  data: any[]
  status: 'default' | 'loading' | 'success' | 'error'
  error?: string
}
export const initialState: ITransferCredit = {
  data: [],
  status: 'default',
}

const TransferReducer = (state = initialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case SUCCESS_TRANSFER:
      newState.status = 'success'
      break
    case DEFAULT_TRANSFER:
      newState.status = 'default'
      break
    case LOADING_TRANSFER:
      newState.status = 'loading'
      break
    default:
  }
  return newState
}

export default TransferReducer
