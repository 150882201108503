import {CurrencySymbol, GetFormattedCurrency} from './currencyFormated'

const CurrencyFormat = ({
  value,
  locale,
  withoutsymbol = false,
}: {
  value: any
  locale?: string
  withoutsymbol?: boolean
}) => {
  return (
    <>
      {!withoutsymbol ? CurrencySymbol() : ''}
      {GetFormattedCurrency({
        value: value,
        locale: locale,
      })}
    </>
  )
}

export {CurrencyFormat}
