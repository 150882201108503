export const messages = {
  get_order_failed:
    'Oops, something wrong while loading data from server, please reload to try again!',
  create_update_order_failed:
    'Oops, something wrong while submitting your order, please try again!',
  get_account_balance_failed:
    'Oops, something wrong while get your current account balance, please reload the page to try again!',
  topup_account_balance_failed:
    'Oops, something wrong while topup your balance. Please contact us if anything wrong with balance transfer.',
  network_error: 'Oops something went wrong, please try again!',
  access_denied: 'You dont have permission to access this page, please re-login!',
  no_any_profile:
    'You have no company to manage with this business dashboard, please sign up your company!',
  resend_gift_success: 'Email gift(s) has been resent',
  resend_gift_failed: 'Oops, something wrong while resend your gift. Please try again!',
  get_transaction_failed: 'Oops, no transaction available. Please try another keyword and dates!',
  get_transaction_detail_failed:
    'Oops, transaction not found, please back to list and select gift!',
  create_gift_invalid_time: 'Please select delivery date and time at least 1 hour from now!',
  create_gift_no_gift_selected: 'Please select the gift!',
  create_gift_no_recipient: 'Please enter the recipient(s)',
  create_gift_invalid_recipient: 'Any invalid recipient email, please recheck your input!',
  create_gift_email_preview_no_gift: 'Please select the gift before you see the email preview',
  create_giftb_invalid_email: 'Please recheck your input email!',
  payment_notification_sent: 'Email payment notification sent. Thank you',
  payment_notification_unsent:
    'Oops, something wrong while send email notification to our finance, please try again or contact us via email instead. Thank you',
  topup_success: 'Topup Balance success, please reload the page if your balance not refreshed!',
  reset_password_success: 'The password reset link has been sent to your email',
  reset_password_error:
    'Oops something wrong while sending reset password into your email, please try again!',
  resend_voucher_success: 'Resend voucher successfully',
  resend_voucher_error: 'Oops, something wrong while resend your gift',
  resend_all_gift_success: 'Resend all gift successfully',
  resend_all_gift_error: 'Oops, something wrong while resend all voucher',
  draft_save_success: 'Draft saved successfully',
  draft_save_error: 'Oops, something wrong while update draft order',
  draft_update_success: 'Update draft order successfully.',
  draft_update_error: 'Oops, something wrong while update draft order, please try again!',
  draft_delete_success: 'Draft order deleted successfully',
  draft_delete_error: 'Oops, something wrong while delete draft order, please try again!',
  user_create_success: 'User has been registered',
  user_create_error: 'Oops, something wrong while registering user',
  register_fullname_required: 'Fullname is required',
  register_address_required: 'Address is required',
  register_email_required: 'Email is required',
  register_phone_required: 'Phone Number is required',
  register_number_required: 'Registration Number is required',
  register_date_required: 'Registration date required',
  register_business_name_required: 'Company name is required',
  register_business_type_required: 'Business type is required',
  register_primary_act_required: 'Primary Activity is required',
  register_business_address_required: 'Registration address is required',
  register_country_required: 'Country is required',
}
