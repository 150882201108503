import {Dispatch} from 'react'
import {toast} from 'react-toastify'
import {messages} from '../../Messages'
import {
  // checkUenCorporate,
  postCorporate,
  postCorporatePotential,
} from '../../services/corporate/CorporateServices'
import * as Sentry from '@sentry/react'
import {IAction} from '../store'
import {ERROR_CORPORATE, LOADING_CORPORATE, SUCCESS_CORPORATE} from './CorporateActionTypes'
import {getXAppName} from '../../helper'

export const ActPostRegisterCorporate =
  (params: postCorporatePotential) => async (dispatch: Dispatch<IAction>) => {
    dispatch({type: LOADING_CORPORATE})
    // console.log(params)
    // const {details} = params
    // const {company_profile} = details
    // const {registration_number} = company_profile

    let ipClient = await fetch('/ipinfo', {
      headers: {
        'Content-Type': 'application/json',
        'x-from-app-name': getXAppName(),
      },
    })
    await ipClient.json()

    try {
      let res = await postCorporate(params)
      const {code, data} = res
      if (code === 200) {
        return dispatch({type: SUCCESS_CORPORATE, payload: {data: data}})
      } else {
        // toast.warning(res.error);
        return dispatch({
          type: ERROR_CORPORATE,
          payload: {error: res && res.error ? res.error : 'Something went wrong'},
        })
      }
      // return res
    } catch (error) {
      Sentry.captureException(error)
      toast.error(messages.network_error)

      return dispatch({
        type: ERROR_CORPORATE,
        payload: {error: 'Something went wrong'},
      })
    }
  }

export const getCorporateProfileAct =
  (corporate_id: string) => async (dispatch: Dispatch<IAction>) => {}
