import React, {useState, useRef, useEffect, FC} from 'react'
import {DropzoneComponent} from 'react-dropzone-component'
import {FaEdit, FaPlus, FaTrash} from 'react-icons/fa'
import {useSelector} from 'react-redux'
import {Button, Progress} from 'reactstrap'
import {CONNECTION, CONFIG_STRAPI_CONTENT, log} from '../../helper'
import {RootState} from '../../redux/rootReducer'

const strapiBaseURL = process.env.REACT_APP_BASEURL_CONTENT

export const StrapiThumbnail: FC<{value: any; onChange: (value: any) => void}> = (props) => {
  const strapiUrl = 'https://content.giftano.com'
  const styleBox: any = {
    border: '2px dashed gray',
    borderRadius: '4px',
    textAlign: 'center',
  }
  const fileEditRef = useRef(null)
  const fileAddRef = useRef(null)
  const strapiToken: string = useSelector((state: RootState) => state.auth.strapi_token) || ''
  const [uploading, setUploading] = useState(false)
  const [currentFile, setCurrentFile] = useState<any>(null)
  useEffect(() => {
    if (props.value) {
      setCurrentFile(props.value)
    } else {
      setCurrentFile(null)
    }
  }, [props.value])
  return (
    <div style={styleBox}>
      {uploading ? (
        <div style={{padding: '24px'}}>
          <Progress value={100} animated />
        </div>
      ) : (
        <React.Fragment>
          {currentFile && currentFile.url ? (
            <React.Fragment>
              <img
                src={
                  strapiUrl +
                  (currentFile.formats && currentFile.formats.thumbnail
                    ? currentFile.formats.thumbnail.url
                    : currentFile.url)
                }
                style={{
                  margin: '16px',
                  maxHeight: '200px',
                  boxShadow: '5px 5px 10px gray',
                }}
                alt='Giftano'
              />
              <div style={{marginBottom: '24px'}}>
                <input
                  type='file'
                  ref={fileEditRef}
                  onChange={(e: any) => {
                    let file = e.target.files[0]
                    if (file) {
                      setUploading(true)
                      let formData = new FormData()
                      formData.append('files', file, file.name)
                      const config = CONFIG_STRAPI_CONTENT(
                        {
                          url: strapiBaseURL + '/upload',
                          method: 'POST',
                          data: formData,
                        },
                        strapiToken
                      )
                      CONNECTION(config)
                        .then((resUpload) => {
                          log('upload result', resUpload)
                          setCurrentFile(resUpload.data[0])
                          if (props.onChange) {
                            props.onChange(resUpload.data[0])
                          }
                          setUploading(false)
                        })
                        .catch((errUpload) => {
                          log('error upload', errUpload)
                          setUploading(false)
                        })
                    }
                  }}
                  style={{display: 'none'}}
                />
                <Button
                  size='sm'
                  color='primary'
                  onClick={() => {
                    if (fileEditRef.current) {
                      const el: HTMLElement = fileEditRef.current
                      el.click()
                    }
                  }}
                >
                  <FaEdit />
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  className='ml-2'
                  onClick={() => {
                    setUploading(true)
                    const config = CONFIG_STRAPI_CONTENT(
                      {
                        url: strapiBaseURL + '/upload/files/' + currentFile._id,
                        method: 'DELETE',
                      },
                      strapiToken
                    )
                    CONNECTION(config)
                      .then((resDelete) => {
                        log('result delete', resDelete)
                        setCurrentFile(null)
                        if (props.onChange) {
                          props.onChange('')
                        }
                        setUploading(false)
                      })
                      .catch((errDelete) => {
                        log('error Delete', errDelete)
                        setUploading(false)
                      })
                  }}
                >
                  <FaTrash />
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <input
                type='file'
                ref={fileAddRef}
                onChange={(e: any) => {
                  let file = e.target.files[0]
                  if (file) {
                    setUploading(true)
                    let formData = new FormData()
                    formData.append('files', file, file.name)
                    const config = CONFIG_STRAPI_CONTENT(
                      {
                        url: strapiBaseURL + '/upload',
                        method: 'POST',
                        data: formData,
                      },
                      strapiToken
                    )
                    CONNECTION(config)
                      .then((resUpload) => {
                        log('upload result', resUpload)
                        setCurrentFile(resUpload.data[0])
                        if (props.onChange) {
                          props.onChange(resUpload.data[0])
                        }
                        setUploading(false)
                      })
                      .catch((errUpload) => {
                        log('error upload', errUpload)
                        setUploading(false)
                      })
                  }
                }}
                style={{display: 'none'}}
              />
              <Button
                color='primary'
                className='m-4'
                onClick={() => {
                  if (fileAddRef.current) {
                    const el: HTMLElement = fileAddRef.current
                    el.click()
                  }
                }}
              >
                <FaPlus className='mr-2' />
                Select Image
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export const StrapiUpload: FC<any> = (props) => {
  const strapiToken: string = useSelector((state: RootState) => state.auth.strapi_token) || ''
  let config: any = {
    // autoProcessQueue: false,
    addRemoveLinks: true,
    paramName: 'files',
    headers: {
      Authorization: 'Bearer ' + strapiToken,
      'Cache-Control': null,
      'X-Requested-With': null,
    },
  }
  if (props.maxFiles) {
    config.maxFiles = props.maxFiles
  }
  if (props.maxFilesize) {
    config.maxFilesize = props.maxFilesize
  }
  return (
    <div>
      <DropzoneComponent
        config={{
          iconFiletypes: ['.jpg', '.png', '.gif'],
          showFiletypeIcon: true,
          postUrl: 'https://content.giftano.com/upload',
        }}
        djsConfig={config}
        eventHandlers={{
          success: (e, res) => {
            if (props.onChange) {
              props.onChange(e, res)
            }
          },
          removedfile: (e) => {
            if (props.onChange) {
              props.onChange(e)
            }
          },
        }}
      />
    </div>
  )
}
