import {CONFIG, CONNECTION} from '../../helper'

const endpoint = process.env.REACT_APP_ENDPOINT_PAYMENT_REQUEST
const paymentGatewayIdForBraintree = '5eda4587e5aec85cd1a5e35c'

export const getPaymentGatewayClientToken = async ({
  paymentGatewayId = paymentGatewayIdForBraintree,
  corporateId,
}: {
  paymentGatewayId?: string
  corporateId?: string
}) => {
  let config = CONFIG({
    url:
      endpoint +
      '/paymentgateway-client-token/' +
      paymentGatewayId +
      (corporateId ? `?id=${corporateId}&type=corporate` : ''),
  })
  return await CONNECTION(config)
}

export const createPaymentTransaction = async ({
  payment,
  paymentGatewayId = paymentGatewayIdForBraintree,
}: {
  payment: any
  paymentGatewayId?: string
}) => {
  let config = CONFIG({
    url: endpoint + '/paymentgateway-transaction/' + paymentGatewayId,
    method: 'POST',
    data: payment,
  })
  return await CONNECTION(config)
}
