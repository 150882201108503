import {useDispatch, useSelector} from 'react-redux'
import {Form, Modal, ModalBody, ModalHeader, UncontrolledTooltip} from 'reactstrap'
import {getModal} from '../../../../app/redux/modal/ModalActions'
import {RootState} from '../../../../app/redux/rootReducer'
import Select from 'react-select'
import {GiButton} from '../../../../app/components/system-ui'
import {AUTH_SET_COMPANY} from '../../../../app/redux/auth/AuthActionTypes'
import {getCorporateDetail} from '../../../../app/services/corporate/LegacyService'
// import { getCorporateDetail } from '../../../../app/services/corporate/LegacyService'
import Country from '../../../../app/assets/json/country.json'
import './ModalCompany.scss'
import {toAbsoluteUrl} from '../../../helpers/AssetHelpers'
import {GoUnverified, GoVerified} from 'react-icons/go'
import {useState} from 'react'

export default function ModalCompany() {
  const modal: any = useSelector((state: any) => state.modal)
  const dispatch = useDispatch()
  const {profile_accesses} = useSelector((state: RootState) => state.auth.profile)
  const {corporate_id} = useSelector((state: RootState) => state.auth.company)

  const {MODAL_COMPANY} = modal

  const handleModal = () => {
    return dispatch(getModal({modal: 'MODAL_COMPANY', open: false}))
  }

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const onSumbit = async (e: any) => {
    e.preventDefault()
    const select = e.target.company.value
    if (select && profile_accesses) {
      let companyProfile = profile_accesses.filter((c: any) => c.corporate_id === select)[0]
      let companyResp = await getCorporateDetail({corporateid: select})
      // eslint-disable-next-line eqeqeq
      if (companyResp.status == 1) {
        companyProfile.corporate_detail = companyResp.data
      }
      dispatch({type: AUTH_SET_COMPANY, payload: {company: companyProfile}})
      handleModal()
      // window.location.reload();
    }
  }

  // eslint-disable-next-line
  const companyOpts = profile_accesses
    ? profile_accesses
        .filter((i: any) => i.corporate_detail)
        .map((c: any) => ({
          value: c.corporate_id,
          label: c.corporate_detail.name,
          department: c.corporate_detail.department,
          logo: c.corporate_detail.logo,
          verified: c.corporate_detail.is_verified,
          // eslint-disable-next-line
          countryname: Country.find((obj) => obj.alpha2 == c.corporate_detail.country)?.name,
        }))
    : []

  return (
    <div>
      <Modal centered className='modal-select-company' isOpen={MODAL_COMPANY}>
        <Form onSubmit={(e) => onSumbit(e)} method='POST'>
          <ModalHeader>
            {' '}
            <img
              alt='Logo'
              width={70}
              height={70}
              src={toAbsoluteUrl('/media/icons/office-building.svg')}
              className='me-4'
            />{' '}
            Select Company
          </ModalHeader>

          <ModalBody>
            <Select
              className='react-select-company'
              options={companyOpts.filter((i: any) => i.value !== corporate_id)}
              name='company'
              styles={{
                singleValue: (base: any) => ({
                  ...base,
                  display: menuIsOpen ? 'none' : 'block',
                }),
                input: (styles: any) => ({
                  ...styles,
                  fontSize: '24px',
                }),
                placeholder: (styles: any) => ({
                  ...styles,
                  fontSize: '24px',
                }),
                dropdownIndicator: (styles: any) => ({
                  ...styles,
                  display: menuIsOpen ? 'none' : 'block',
                }),
              }}
              onMenuOpen={() => setMenuIsOpen(true)}
              onMenuClose={() => setMenuIsOpen(false)}
              menuIsOpen={menuIsOpen}
              placeholder={'Type here to search your company'}
              backspaceRemovesValue={false}
              autoFocus
              controlShouldRenderValue={!menuIsOpen}
              defaultValue={companyOpts.filter((c: any) => c.value === corporate_id)}
              formatOptionLabel={({label, department, logo, countryname, verified}) => (
                <div className='d-flex align-items-center'>
                  {logo ? (
                    <img
                      className='logo'
                      src={logo}
                      alt='Logo'
                      style={{width: '60px', marginRight: '10px'}}
                    />
                  ) : (
                    <div style={{width: '60px', marginRight: '10px'}} className='logo'>
                      {label
                        .split(' ')
                        .map((word: string) => word.charAt(0))
                        .join('')}
                    </div>
                  )}
                  <div className='company-information'>
                    <div className='name'>
                      {label}
                      <>
                        {
                          // eslint-disable-next-line
                          verified == 1 ? (
                            <div className='verified-status'>
                              <span
                                className='text-primary'
                                // style={{ marginLeft: '8px', marginRight: '8px', fontSize: '150%', marginTop: '6px' }}
                                id='btnVerified'
                              >
                                <GoVerified />
                              </span>
                              <UncontrolledTooltip target={'btnVerified'}>
                                Verified
                              </UncontrolledTooltip>
                            </div>
                          ) : (
                            <div className='verified-status'>
                              <span id='btnUnverified'>
                                <GoUnverified />
                              </span>
                              <UncontrolledTooltip target={'btnUnverified'}>
                                Unverified
                              </UncontrolledTooltip>
                            </div>
                          )
                        }
                      </>
                    </div>
                    <div>{department ? department : '_'}</div>
                  </div>
                  {countryname && (
                    <div className='country-flag'>
                      {/* Country : */}
                      <img
                        src={require('../../../../../public/media/flags/' +
                          countryname.replace(' ', '-').toLowerCase() +
                          '.svg')}
                        width='22px'
                        style={{borderRadius: '3px', marginRight: '10px'}}
                        alt=''
                      />
                      {/* <span style={{fontSize: '20px'}}>{countrySelected?.emoji}</span>{' '} */}
                      {/* {countryname} */}
                    </div>
                  )}
                </div>
              )}
            />
            <div className='footer text-center '>
              <GiButton
                className='rounded-pill me-3'
                color='primary'
                outline
                size='sm'
                onClick={() => handleModal()}
              >
                Close
              </GiButton>
              <GiButton className='rounded-pill' type='submit' color='primary' size='sm'>
                Switch
              </GiButton>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    </div>
  )
}
