import {IAction} from '../store'
import {
  ERROR_PERMISSION_MENU,
  LOADING_PERMISSION_MENU,
  SUCCESS_PERMISSION_MENU,
} from './PermissionMenuActionTypes'

export interface IPermissionMenuState {
  data: any[]
  status: 'pending' | 'loading' | 'success' | 'error'
  error?: string
}

const InitialState: IPermissionMenuState = {
  data: [],
  status: 'pending',
}

const PermissionMenuReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_PERMISSION_MENU:
      newState.status = 'loading'
      break
    case SUCCESS_PERMISSION_MENU:
      newState.status = 'success'
      newState.data = action.payload.data
      break
    case ERROR_PERMISSION_MENU:
      newState.status = 'error'
      break
    default:
  }
  return newState
}

export default PermissionMenuReducer
