export const LOADING_USERMANAGEMENT = 'LOADING_USERMANAGEMENT'
export const SUCCESS_USERMANAGEMENT = 'SUCCESS_USERMANAGEMENT'
export const GET_USERMANAGEMENT = 'GET_USERMANAGEMENT'
export const RELOAD_USERMANAGEMENT = 'RELOAD_USERNAMAGEMENT'

export const ERROR_USERMANAGEMENT = 'ERROR_USERMANAGEMENT'
export const EXSITS_USERMANAGEMENT = 'EXSITS_USERMANAGEMENT'
export const NOTEXSITS_USERMANAGEMENT = 'NOTEXSITS_USERMANAGEMENT'

export const DEFAULT_USERMANAGEMENT = 'DEFAULT_USERMANAGEMENT'
export const RESET_USERMANAGEMENT = 'RESET_USERMANAGEMENT'
