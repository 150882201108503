import {BsExclamationTriangle} from 'react-icons/bs'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap'
import {Dispatch} from 'redux'
import {getModal} from '../../redux/modal/ModalActions'
import {RootState} from '../../redux/rootReducer'

const ModalUen = () => {
  const dispatch: Dispatch<any> = useDispatch()
  const modal: any = useSelector((state: RootState) => state.modal)
  const modulCorporate = useSelector((state: RootState) => state.modulCorporate)
  const {MODAL_EXISTS} = modal

  const closeModal = () => {
    dispatch(getModal({modal: 'MODAL_EXISTS', open: false}))
  }

  return (
    <Modal isOpen={MODAL_EXISTS} toggle={closeModal}>
      <ModalHeader style={{borderBottom: 'none'}} toggle={closeModal}></ModalHeader>
      <ModalBody>
        <p className='text-center' style={{fontSize: '36px', color: 'var(--gf-warning-300)'}}>
          <BsExclamationTriangle />
        </p>
        <h4 className='text-center mb-6'>Something went wrong</h4>
        <div
          className='text-center'
          dangerouslySetInnerHTML={{
            __html:
              modulCorporate.error ||
              '<p>Sorry, we’re unable to process your application.</p><p>For more information please contact us at <a href="mailto:grow@giftano.com">grow@giftano.com</a></p>',
          }}
        ></div>
        <div className='text-center' style={{marginTop: '30px', marginBottom: '40px'}}>
          <Button size='sm' color='primary' onClick={closeModal}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalUen
