import {createSlice} from '@reduxjs/toolkit'

interface IWhitelabelListState {
  data: any[]
  status: 'pending' | 'loading' | 'success' | 'error'
}

const initialState: IWhitelabelListState = {
  data: [],
  status: 'pending',
}

const WhitelabelListSlice = createSlice({
  name: 'whitelabel-list',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.status = 'loading'
    },
    setSuccess: (state, action) => {
      state.data = action.payload.data || []
      state.status = 'success'
    },
    setError: (state) => {
      state.status = 'error'
    },
  },
})

export const {setLoading, setSuccess, setError} = WhitelabelListSlice.actions

export const WhitelabelListReducer = WhitelabelListSlice.reducer

export default WhitelabelListReducer
