import {IAction} from '../store'
import {ERROR_STRAPI, LOADING_STRAPI, SUCCESS_STRAPI} from './StrapiActionTypes'

interface strapiInterface {
  data: any
  status: 'pending' | 'success' | 'error' | 'loading'
}

const initialState: strapiInterface = {
  data: {},
  status: 'pending',
}

const StrapiReducer = (state = initialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_STRAPI:
      newState.status = 'loading'
      break
    case SUCCESS_STRAPI:
      newState.status = 'success'
      newState.data = action.payload.data || {}
      break
    case ERROR_STRAPI:
      newState.status = 'error'
      break
    default:
      break
  }
  return newState
}

export default StrapiReducer
