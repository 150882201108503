import {createContext, FC, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {messages} from '../../Messages'
import {getRoutesAct} from '../../redux/app/RouteAction'
import {getPermissionMenuAct, getPermissionModulesAct, setSessionId} from '../../redux/auth'
import {getProfileAct, logoutAct} from '../../redux/auth/AuthActions'
import {RootState} from '../../redux/rootReducer'
import PouchDB from 'pouchdb'
import {ActionGetStrapiContent} from '../../redux/strapi/StrapiActions'
import SelectCorporatePage from '../../pages/select-corporate'
import {actGetAppString} from '../../redux/app-string'

type InitAuthenticate = {
  status?: 'pending' | 'success' | 'failed'
  auth: boolean
  children?: React.ReactNode
}

const PropsAuthenticate: InitAuthenticate = {
  status: 'pending',
  auth: false,
}
export const IComContext = createContext<InitAuthenticate>(PropsAuthenticate)

const AuthInit: FC = ({children}) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const session_id = useSelector((state: RootState) => state.auth.session_id)
  const company = useSelector((state: RootState) => state.auth.company)
  const profile: any = useSelector((state: RootState) => state.auth.profile, shallowEqual)
  const routeStatus = useSelector((state: RootState) => state.router.status)
  // const permissionMenuStatus = useSelector((state: RootState) => state.permissionMenu.status)
  // const permissionModulesStatus = useSelector((state: RootState) => state.permissionModules.status)
  const strapiStatus = useSelector((state: RootState) => state.strapi.status)
  const appStringStatus = useSelector((state: RootState) => state.appString.status)

  const dispatch = useDispatch()
  const [IsAuthenticate, setAuthentice] = useState<InitAuthenticate>({
    status: 'pending',
    auth: false,
  })
  useEffect(() => {
    // init auth here
    if (!session_id) {
      // try to get from param
      localStorage.removeItem('session_id')

      const params = new URLSearchParams(window.location.search)
      const paramSessionId = params.get('session_id')
      if (paramSessionId) {
        localStorage.removeItem('iComConfig')
        try {
          let db = new PouchDB('catalog')
          db.destroy()
        } catch {}
        localStorage.removeItem('company_id')
        // remove param from url and dispatch session id
        // window.history.pushState('', '', window.location.origin + window.location.pathname)
        dispatch(setSessionId(atob(paramSessionId)))
      } else {
        // dont have session id, redirect to login
        // const redirectUrl =
        //   window.location.origin + window.location.pathname + '|' + process.env.REACT_APP_NAME
        // window.location.href = process.env.REACT_APP_AUTH_URL + '?redirect=' + btoa(redirectUrl)
        setShowSplashScreen(false)
      }
    } else {
      dispatch(getProfileAct())
    }
  }, [session_id, dispatch])

  useEffect(() => {
    if (profile && profile.id) {
      const {profile_accesses} = profile

      if (profile_accesses && profile_accesses.length > 0) {
        if (routeStatus === 'pending') {
          dispatch(getRoutesAct())
        }
        if (strapiStatus === 'pending') {
          dispatch(ActionGetStrapiContent())
        }
        if (routeStatus === 'success') {
          setShowSplashScreen(false)
          setAuthentice({
            status: 'success',
            auth: true,
          })
        }
        if (appStringStatus === 'pending') {
          dispatch(actGetAppString())
        }
      } else {
        console.log('You dont have permission in this apps')
        toast.warning(messages.access_denied)
        dispatch(logoutAct())
        // const redirectUrl = window.location.origin + window.location.pathname + '|' + process.env.REACT_APP_NAME;
        // window.location.href = process.env.REACT_APP_AUTH_URL + ''
      }
    }
  }, [profile, routeStatus, strapiStatus, dispatch, appStringStatus])

  useEffect(() => {
    if (company && company.role_id) {
      const role_id = company.role_id
      dispatch(getPermissionMenuAct(role_id))
      dispatch(getPermissionModulesAct(role_id))
    }
  }, [company, dispatch])

  return (
    <IComContext.Provider value={{...IsAuthenticate}}>
      {showSplashScreen ? (
        <SplashScreen />
      ) : session_id && !company ? (
        <SelectCorporatePage />
      ) : (
        <>{children}</>
      )}
    </IComContext.Provider>
  )
}

const SplashScreen = () => (
  <div className='splash-screen'>
    <img
      src='https://static-cdn.giftano.com/assets/giftano-logo-horizontal.png'
      alt='Logo'
      height={60}
    />
    <span>Authenticating ...</span>
  </div>
)

export default AuthInit
