import {CONFIG, CONFIG_CP, CONFIG_STRAPI_CONTENT, CONNECTION} from './connection'
import {log} from './logger/Log'
import CryptoJS from 'crypto-js'

export const appName = process.env.REACT_APP_NAME || 'b2b-dashboard'

export const getXAppName = () => {
  const encrypted = CryptoJS.AES.encrypt(appName, window.location.hostname).toString()
  return encrypted
}

export {CONFIG, CONFIG_CP, CONFIG_STRAPI_CONTENT, CONNECTION, log}
