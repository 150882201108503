import {IAction} from '../store'
import {SHOW_MODAL_SELECT_CREATE_GIFT} from './SelectCreateGiftTypes'

interface ISelectCreateState {
  modalShow: boolean
}

const initialState: ISelectCreateState = {
  modalShow: false,
}

const SelectCreateGiftReducer = (state = initialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case SHOW_MODAL_SELECT_CREATE_GIFT:
      newState.modalShow = action.payload.show ? true : false
      break
    default:
      break
  }
  return newState
}

export default SelectCreateGiftReducer
