import {IAction} from '../store'
import {ERROR_APP_STRING, LOADING_APP_STRING, SUCCESS_APP_STRING} from './AppStringActionTypes'

export interface IAppString {
  data: any
  status: 'pending' | 'loading' | 'success' | 'error'
}

const initialState: IAppString = {
  data: {},
  status: 'pending',
}

const AppStringReducer = (state = initialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_APP_STRING:
      newState.status = 'loading'
      break
    case SUCCESS_APP_STRING:
      newState.data = action.payload.data || {}
      newState.status = 'success'
      break
    case ERROR_APP_STRING:
      newState.status = 'error'
      break
    default:
      break
  }
  return newState
}

export default AppStringReducer
