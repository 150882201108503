import { IAction } from "../store"
import { ADD_ITEM_ORDERGO, DELETE_ITEM_ORDERGO, ERROR_ORDERGO, LOADING_ORDERGO, RELOAD_ORDERGO, SUCCESS_ORDERGO, UPDATE_ITEM_ORDERGO } from "./OrderGoActionTypes"


export interface IOrderGoState {
    data: any[]
    status: 'pending' | 'loading' | 'success' | 'error'
    error?: string
}

const InitialState: IOrderGoState = {
    data: [],
    status: 'pending',
}

const OrderGoReduce = (state = InitialState, action: IAction) => {
    let newState = { ...state }
    switch (action.type) {
        case LOADING_ORDERGO:
            newState.status = 'loading'
            break
        case SUCCESS_ORDERGO:
            newState.status = 'success'
            newState.data = action.payload.data
            break
        case ERROR_ORDERGO:
            newState.status = 'error'
            break
        case ADD_ITEM_ORDERGO:
            newState.data.unshift({ ...action.payload.data })
            break
        case UPDATE_ITEM_ORDERGO:
            newState.data = newState.data.map((i) =>
                i.id === action.payload.id ? { ...action.payload.data } : { ...i }
            )
            break
        case DELETE_ITEM_ORDERGO:
            newState.data = newState.data.filter((i) => i.id !== action.payload.id)
            break
        case RELOAD_ORDERGO:
            newState.status = 'pending'
            break
        default:
    }
    return newState
}

export default OrderGoReduce