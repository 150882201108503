import {Dispatch} from 'react'
import {toast} from 'react-toastify'
import {messages} from '../../Messages'
import {getAccountBalance, getAccountBalanceParams} from '../../services/corporate/LegacyService'
import {IAction} from '../store'
import {
  ERROR_ACCOUNT_BALANCE,
  LOADING_ACCOUNT_BALANCE,
  SUCCESS_ACCOUNT_BALANCE,
} from './CorporateActionTypes'
import * as Sentry from '@sentry/react'

export const actGetAccountBalance =
  (params: getAccountBalanceParams) => async (dispatch: Dispatch<IAction>) => {
    dispatch({type: LOADING_ACCOUNT_BALANCE})
    try {
      let res = await getAccountBalance(params)
      if (res.status === 1) {
        // console.log('resp', resp);
        dispatch({
          type: SUCCESS_ACCOUNT_BALANCE,
          payload: {credits: parseFloat(res.data.credits)},
        })
        return res.data.credits
      } else {
        toast.warning(res.message || messages.get_account_balance_failed)
        dispatch({type: ERROR_ACCOUNT_BALANCE, payload: {error: res.message}})
      }
    } catch (err: any) {
      Sentry.withScope((scope) => {
        scope.setExtra('corporate_id', params.corporateid)
        Sentry.captureException(err && err.response ? err.response : err)
      })
      toast.error(messages.network_error)
      dispatch({type: ERROR_ACCOUNT_BALANCE, payload: {error: err}})
    }
  }
