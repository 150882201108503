import {IAction} from '../store'
import {
  ERROR_CHECKINGUSER,
  LOADING_CHEKCINGUSER,
  SUCCESS_CHECKINGUSER,
} from './AuthUserCheckingTypes'

export interface StateDefault {
  status: 'pending' | 'success' | 'default' | 'error'
  result: any
}

const initialState: StateDefault = {
  status: 'default',
  result: [],
}

const AuthUserChekckingReducer = (state = initialState, action: IAction) => {
  let newstate: StateDefault = {...state}

  switch (action.type) {
    case LOADING_CHEKCINGUSER:
      newstate.status = 'pending'
      break
    case SUCCESS_CHECKINGUSER:
      newstate.status = 'success'
      newstate.result = action.payload.data
      break
    case ERROR_CHECKINGUSER:
      newstate.status = 'error'
      newstate.result = []
      break
    default:
      break
  }

  return newstate
}

export default AuthUserChekckingReducer
