/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React from 'react'
import {BsQuestionCircle} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {Badge} from 'reactstrap'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import AccountBalance from '../../../partials/layout/header-menus/AccountBalance'
import {Companies} from '../../../partials/layout/header-menus/Companies'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-2.svg')} className='h-30px' />
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/' className='d-lg-none'>
              <img
                alt='Logo'
                src={'https://static-cdn.giftano.com/assets/giftano-logo-horizontal.png'}
                className='h-30px'
              />
            </Link>
          </div>
        )}

        <div className='custom-tool-mobile d-none'>
          <Companies />
          <AccountBalance />
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex  align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div
            className='d-flex align-items-left my-custom-toolbar d-none'
            style={{width: '-webkit-fill-available'}}
          >
            <Companies />

            {process.env.REACT_APP_BUILD_MODE === 'SANDBOX' && (
              <div className='justify-content-center d-flex justify-items-center align-items-center'>
                <Badge className='env-indicator' color='warning'>
                  SANDBOX ENV
                </Badge>
              </div>
            )}

            <AccountBalance />

            <div
              className='d-flex justify-content-center align-items-center'
              style={{paddingLeft: '20px', paddingRight: '10px'}}
            >
              <Link to={'/faq'} title={"FAQ's"} style={{fontWeight: 'bold', fontSize: '20px'}}>
                <BsQuestionCircle />
              </Link>
            </div>
          </div>

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
