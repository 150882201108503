import {IAction} from '../store'
import {
  ERROR_ACCOUNT_BALANCE,
  LOADING_ACCOUNT_BALANCE,
  RELOAD_ACCOUNT_BALANCE,
  SET_MODAL_DATA_ACCOUNT_BALANCE,
  SHOW_TOPUP_ACCOUNT_BALANCE,
  SUCCESS_ACCOUNT_BALANCE,
} from './CorporateActionTypes'

export interface IAccountBalanceState {
  credits: number
  status: 'pending' | 'loading' | 'success' | 'error'
  error?: any
  showTopup: boolean
  orderId?: string
  amount?: string
  financeFee?: string
  gst?: string
  amountToPay?: string
}

const initialState: IAccountBalanceState = {
  credits: 0,
  status: 'pending',
  showTopup: false,
}

const AccountBalanceReducer = (state = initialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_ACCOUNT_BALANCE:
      newState.status = 'loading'
      break
    case SUCCESS_ACCOUNT_BALANCE:
      newState.status = 'success'
      newState.credits = action.payload.credits || 0
      break
    case ERROR_ACCOUNT_BALANCE:
      newState.status = 'error'
      newState.error = action.payload.error || 'Unknown error'
      break
    case SHOW_TOPUP_ACCOUNT_BALANCE:
      newState.showTopup = action.payload.show || false
      newState.amount = action.payload.amount || 0
      newState.orderId = action.payload.orderId || ''
      break
    case RELOAD_ACCOUNT_BALANCE:
      newState.status = 'pending'
      break
    case SET_MODAL_DATA_ACCOUNT_BALANCE:
      if (action.payload.amount !== undefined) newState.amount = action.payload.amount
      if (action.payload.financeFee !== undefined) newState.financeFee = action.payload.financeFee
      if (action.payload.gst !== undefined) newState.gst = action.payload.gst
      if (action.payload.amountToPay !== undefined)
        newState.amountToPay = action.payload.amountToPay
      if (action.payload.orderId !== undefined) newState.orderId = action.payload.orderId
      break
    default:
  }
  return newState
}

export default AccountBalanceReducer
