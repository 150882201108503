import {IAction} from '../store'
import {
  ERROR_CORPORATE,
  EXIST_CORPORATE,
  LOADING_CORPORATE,
  SUCCESS_CORPORATE,
} from './CorporateActionTypes'

interface ICorporate {
  data: {}
  status: 'pending' | 'loading' | 'error' | 'success' | 'default' | 'exsits'
  error?: any
}

const InitialState: ICorporate = {
  status: 'default',
  data: {},
}

const CorporateReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_CORPORATE:
      newState.status = 'loading'
      break
    case SUCCESS_CORPORATE:
      newState.data = action.payload.data
      newState.status = 'success'
      break
    case ERROR_CORPORATE:
      newState.status = 'error'
      newState.error =
        action.payload.error === undefined ? action.payload.error : 'Something went wrong'
      break
    case EXIST_CORPORATE:
      newState.status = 'exsits'
      newState.error = action.payload.error
      break
    default:
      break
  }

  return newState
}

export default CorporateReducer
