import {IAction} from '../store'
import {ERROR_ROLE, LOADING_ROLE, SUCCESS_ROLE} from './RoleTypes'

interface IRole {
  data: any[]
  status: 'pending' | 'loading' | 'success' | 'error'
  error?: string
}

const InitialState: IRole = {
  data: [],
  status: 'pending',
}

const RoleReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_ROLE:
      newState.status = 'loading'
      break
    case SUCCESS_ROLE:
      newState.status = 'success'
      newState.data = action.payload.data
      break
    case ERROR_ROLE:
      newState.status = 'error'
      break
    default:
  }

  return newState
}

export default RoleReducer
