import moment from 'moment'
import {IAction} from '../store'
import {
  ERROR_TRANSACTIONS,
  LOADING_TRANSACTIONS,
  SET_DATE_TRANSACTIONS,
  SET_KEYWORD_TRANSACTIONS,
  SET_PAGE_TRANSACTIONS,
  SUCCESS_TRANSACTIONS,
} from './TransactionActionTypes'

interface ITransactionState {
  data: any[]
  status: 'pending' | 'loading' | 'success' | 'error'
  error?: any
  page?: number
  totalSize?: number
  dateRange: [Date, Date]
  keyword: string
}

const initialState: ITransactionState = {
  data: [],
  status: 'pending',
  page: 1,
  totalSize: 0,
  // dateRange: [moment('2021-05-01').toDate(), moment('2021-05-30').toDate()],
  dateRange: [moment().subtract(30, 'days').toDate(), moment().toDate()],
  keyword: '',
}

const TransactionReducer = (state = initialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_TRANSACTIONS:
      newState.status = 'loading'
      break
    case SUCCESS_TRANSACTIONS:
      newState.status = 'success'
      newState.data = action.payload.data || []
      newState.totalSize = action.payload.totalSize || 0
      break
    case ERROR_TRANSACTIONS:
      newState.status = 'error'
      newState.error = action.payload.error || 'Unknown error'
      newState.data = []
      break
    case SET_PAGE_TRANSACTIONS:
      newState.page = action.payload.page || 1
      break
    case SET_DATE_TRANSACTIONS:
      newState.dateRange = action.payload.dateRange || [new Date(), new Date()]
      break
    case SET_KEYWORD_TRANSACTIONS:
      newState.keyword = action.payload.keyword || ''
      break
    default:
      break
  }
  return newState
}

export default TransactionReducer
