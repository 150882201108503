import {CONFIG, CONNECTION} from '../../helper'

const endpoint = process.env.REACT_APP_ENDPOINT_EMAIL

interface SendEmailParams {
  additional_data?: any
  bcc?: string
  from_email: string
  from_name?: string
  html_tag_content: string
  metadata?: any
  provider_name?: string
  recipients: {
    email: string
    name?: string
    type?: string
  }[]
  scheduling_meta_data?: any
  send_at?: string
  subject: string
  tags?: [string]
  template_components?: {
    company_address?: string
    hide_footer?: boolean
    hide_header?: boolean
    support_email?: string
    web_domain?: string
    web_url?: string
  }
  template_name?: string
}

export const SendEmail = async (params: SendEmailParams) => {
  const config = CONFIG({
    url: endpoint + '/send',
    method: 'post',
    data: params,
  })
  return await CONNECTION(config)
}
