import {toast} from 'react-toastify'
import {Dispatch} from 'redux'
import {getPermissionMenus} from '../../services'
import {IAction} from '../store'
import {
  ERROR_PERMISSION_MENU,
  LOADING_PERMISSION_MENU,
  SUCCESS_PERMISSION_MENU,
} from './PermissionMenuActionTypes'

export const getPermissionMenuAct = (role_id: any) => async (dispatch: Dispatch<IAction>) => {
  dispatch({type: LOADING_PERMISSION_MENU})

  try {
    let res = await getPermissionMenus(role_id)
    if (res.code === 200) {
      dispatch({type: SUCCESS_PERMISSION_MENU, payload: {data: res.data.menus || []}})
    } else {
      toast.warning(
        res.message ||
          'Oops something wrong while get permission menu data, please reload to try again!'
      )
      dispatch({type: ERROR_PERMISSION_MENU, payload: {error: res.error}})
    }
  } catch (err) {
    dispatch({type: ERROR_PERMISSION_MENU, payload: {error: err}})
  }
}
