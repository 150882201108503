import {IAction} from '../store'
import {ERROR_COUNTRY, LOADING_COUNTRY, SUCCESS_COUNTRY} from './CountryTypes'

interface PropsCountry {
  status: 'pending' | 'loading' | 'success' | 'error'
  countries: Array<[]>
}

const InitialState: PropsCountry = {
  status: 'pending',
  countries: [],
}

const CountryReducer = (state = InitialState, action: IAction) => {
  let newState = {...state}
  switch (action.type) {
    case LOADING_COUNTRY:
      newState.status = 'pending'
      break
    case SUCCESS_COUNTRY:
      newState.status = 'success'
      newState.countries = action.payload.data
      break
    case ERROR_COUNTRY:
      newState.status = 'error'
      break
    default:
      break
  }

  return newState
}

export default CountryReducer
