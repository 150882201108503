import axios, {AxiosRequestConfig} from 'axios'
import * as Sentry from '@sentry/react'

var timestamp = new Date()

interface initalConfig extends AxiosRequestConfig {
  url: string
  data: any
}

// default config for connection to gcp
export const CONFIG = (config: AxiosRequestConfig) => {
  config.url = (process.env.REACT_APP_BASEURL || '') + config.url
  if (!config.headers) config.headers = {}
  config.headers['x-giftano-app-id'] = process.env.REACT_APP_GIFTANO_APP_ID || ''
  const session_id = localStorage.getItem('session_id')
  if (session_id) {
    config.headers.session_id = session_id
  }
  config.headers.dc = 'sg'
  return config
}

// config into legacy platform
export const CONFIG_CP = (config: initalConfig) => {
  if (!config.headers) config.headers = {}
  config.headers['x-giftano-app-id'] = process.env.REACT_APP_GIFTANO_APP_ID || ''
  const session_id = localStorage.getItem('session_id')
  if (session_id) {
    config.headers.sessionid = session_id
  }
  config.method = 'POST' // overide all method into post
  if (!config.data || !(config.data instanceof FormData)) {
    config.data = new FormData() // change to new form data
  }
  config.data.append('sales_channel', 'b2b-dashboard')
  return config
}

export const CONFIG_STRAPI_CONTENT = (config: AxiosRequestConfig, token: string) => {
  config.headers.Authorization = 'Bearer ' + token
  return config
}

export const CONNECTION = async (config: AxiosRequestConfig) => {
  try {
    const res = await axios(config)
    if (res.status === 200) {
      const data = res.data
      return data
    }
  } catch (err: any) {
    if (err.response) {
      // handle axios error here
      return err.response.data
    }
    // handle other error here
    Sentry.captureException(err)
    throw err
  }
}

export const UPLOADCDN = async (file: any, folder = 'assets/', overwrite = false) => {
  let fd = new FormData()
  fd.append('file', file)
  fd.append('folder', folder)
  fd.append('overwrite', overwrite ? 'true' : 'false')
  const resUpload = await axios.post('https://giftano.com/api/store-file/', fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer k36ptshxr5hw7hpz3th899uy',
    },
  })
  return resUpload
}

export const UPLOADSTORAGE = async (data: any, folder = 'assets/', overwrite = false) => {
  let fd = new FormData()
  const {filename, file}: any = data
  let pathStorage = process.env.REACT_APP_PATH_STORAGE
  fd.append('fileName', timestamp.getTime() + '_' + filename)
  fd.append('fileToUpload', file)
  fd.append('isPublic', 'true')
  fd.append('isAsset', 'true')

  fd.append('path', folder)

  const config = CONFIG({url: pathStorage + '/upload', data: fd, method: 'POST'})
  const restUpload = await CONNECTION(config)

  // const resUpload = await axios.post('https://giftano.com/api/store-file/', fd, {
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //     Authorization: 'Bearer k36ptshxr5hw7hpz3th899uy',
  //   },
  // })
  return restUpload
}
